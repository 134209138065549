export const getCreditCardInfo = async () => {
  try {
    const response = await fetch(
      'https://react-http-da8e9-default-rtdb.firebaseio.com/card.json'
    );
    if (!response.ok) {
      throw new Error('Error in HTTP');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log({error})
  }
};

export const getAccountSettingsInfo = async () => {
    try {
      const response = await fetch(
        'https://react-http-da8e9-default-rtdb.firebaseio.com/settings.json'
      );
      if (!response.ok) {
        throw new Error('Error in HTTP');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log({error})
    }
  };


  export const getPlanInfo = async () => {
    try {
      const response = await fetch(
        'https://react-http-da8e9-default-rtdb.firebaseio.com/plan.json'
      );
      if (!response.ok) {
        throw new Error('Error in HTTP');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log({error})
    }
  };


  export const getCompanyInfo = async () => {
    try {
      const response = await fetch(
        'https://react-http-da8e9-default-rtdb.firebaseio.com/Company.json'
      );
      if (!response.ok) {
        throw new Error('Error in HTTP');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log({error})
    }
  };