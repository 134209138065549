import React, { FC, useContext, useMemo } from 'react';
import { UserContext } from 'store/UserContext';
import {
  SingleWorkflow,
  Workflows,
} from 'components/DashboardReports/Body/Store-Report/Types';
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { workflowReport, workflow } from 'services/constant/routes';
import { calculateRate, tableSort } from 'utils/common';
import ProductView from 'assets/Images/Dashboard/PrimaryView.svg';
import Table from 'components/Table';
import { ThemeContext } from 'store/ThemeContext';
import { getWorlkflowsStatus } from 'utils/dashboard';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface Props {
  loading: boolean;
  isWorkflowReports?: boolean;
  filteredWorkflows: Workflows;
}

interface StatusStyle {
  [key: string]: string;
  Enabled: string;
  Disabled: string;
  'Enabled(Test Mode)': string;
  Expired: string;
}

const statusStyle: StatusStyle = {
  Enabled: 'text-success bg-success',
  Expired: 'text-red-700 bg-red-100',
  Disabled: 'text-gray-500 bg-gray-100',
  'Enabled(Test Mode)': 'text-blue-500 bg-blue-100',
};

const workflowSort = (rowA: SingleWorkflow, rowB: SingleWorkflow) => {
  return tableSort(rowA.workflowName, rowB.workflowName);
};


const AllWokflows: FC<Props> = ({ loading, isWorkflowReports, filteredWorkflows }) => {
  console.log(filteredWorkflows)

  const userCtx = useContext(UserContext);
  const themeCtx = useContext(ThemeContext);

  const customStyles = {
    table: {
      style: {
        background: 'unset !important',
        overflow: 'auto',
      },
    },

    rows: {
      style: {
        color: '#212529',
        fontSize: '14px',
        borderBottom: 'unset !important',
        backgroundColor: 'unset !important',
        height: '52px',

        '&:hover': {
          backgroundColor:
            themeCtx?.theme === 'dark'
              ? 'rgba(255, 255, 255, 0.07) !important'
              : 'rgb(243,246,249) !important',
        },
      },
    },

    cells: {
      style: {
        display: 'flex',
        paddingRight: '4px',
        paddingLeft: '4px',
        
      },
    },
    body: {
      style: {
        minHeight: '333px !important',
        overflow: 'auto',
        background: 'unset !important',
      },
    },
    headRow: {
      style: {
        borderColor: themeCtx?.theme === 'dark' ? '#41464E' : '#e9ebec',
        background: themeCtx?.theme === 'dark' ? 'unset !important' : '',
      },
    },

    headCells: {
      style: {
        color: '#878A99',
        fontSize: '14px',
        height: '44px',
        fontWeight: 600,
        display: 'flex',
        paddingRight: '4px',
        paddingLeft: '4px',
        background:
          themeCtx?.theme === 'dark'
            ? '#41464E'
            : '#FAFBFF',
      },
    },
  };

  const columns: TableColumn<SingleWorkflow>[] = useMemo(
    () => [
      {
        name: <div className="pl-3">Workflow</div>,
        cell: (row) => {
          return (
            <Tippy interactive={true} content={row.workflowName}>
              <div className="flex items-center pl-3">
                <div>
                  <Link
                    to={row.workflowName === 'Total' ? workflow : `${workflowReport}/${row.workflowId}`}
                    className="flex items-center justify-center text-white rounded-lg"
                  >
                    <p
                      className={`text-sm font-medium text-darkText dark:text-[#878A99] truncate ${!isWorkflowReports && 'w-[240px]'
                        } `}
                    >
                      {row.workflowName === 'Total' ? 'Totals' : row.workflowName}
                    </p>
                  </Link>
                  {!isWorkflowReports && row.workflowName !== 'Total' &&  (
                    <p
                      className={`text-xs px-2 h-5 leading-5 ${statusStyle[getWorlkflowsStatus(row.workflowId, userCtx?.workflows)]
                        } text-center rounded inline-block`}
                    >
                      {getWorlkflowsStatus(row.workflowId, userCtx?.workflows)}
                    </p>
                  )}
                </div>
              </div>
            </Tippy>
          );
        },
        sortable: true,
        sortFunction: workflowSort,
        width: isWorkflowReports ? '450px' : '25%',
      },
      {
        name: 'Status',
        omit: !isWorkflowReports,
        cell: (row) => {
          return (
          
            <div>
              {row.workflowName !== "Total" && 
              <p
                className={`text-xs px-2 ${statusStyle[row.status || '']
                  } text-center rounded inline-block`}
              >
                {row.status}
              </p>}
            </div>
          );
        },
        width: isWorkflowReports ? '' : '75px',
      },
      {
        name: 'Orders',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-[#495057] dark:text-[#CED4DA]  dark:text-white inline-block">
                {row.uniqueConversions}
              </p>
            </div>
          );
        },
        width: isWorkflowReports ? '' : '15%',
      },
      {
        name: 'Revenue',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-[#495057] dark:text-[#CED4DA]  dark:text-white inline-block">
                ${(row.monetaryValue === 0 ? '0' : Math.round(row.monetaryValue).toLocaleString())}
              </p>
            </div>
          );
        },
        width: isWorkflowReports ? '' : '15%',
      },
      {
        name: 'AOV',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-[#495057] dark:text-[#CED4DA]  dark:text-white inline-block">
                ${calculateRate(row.monetaryValue, row.uniqueConversions)}
              </p>
            </div>
          );
        },
        width: isWorkflowReports ? '' : '15%',
      },
      {
        name: 'Recipients',
        cell: (row) => {
          return (
            <p className="text-13 text-[#495057] dark:text-[#CED4DA]  dark:text-white ">
              {row.uniquePeople.toLocaleString()}
            </p>
          );
        },
        width: isWorkflowReports ? '' : '15%',
      },
      {
        name: 'Actions',
        cell: (row) => {
          return (
            <Link
              to={row.workflowName === 'Total' ? workflow : `${workflowReport}/${row.workflowId}`}
              className="flex items-center group cursor-pointer"
            >
              <img
                src={ProductView}
                alt="view"
                className="inline mr-1.5 dark:invert dark:brightness-0"
              />
              <span className="text-primary dark:text-white">View</span>
            </Link>
          );
        },
        width: isWorkflowReports ? '100px' : '15%',
        center: true,
      },
    ],
    []
  );

  return (
    <>
      <div className="grid grid-cols-1 gap-4 font-inter overflow-hidden rounded-xl allWorkFlows">
        <Table
          className="rounded-t-2xl scrollbar-hide overflow-auto p-0 h-[385px]"
          data={filteredWorkflows}
          columns={columns}
          progressPending={loading}
          pagination
          paginationPerPage={isWorkflowReports ? 20 : 5}
          customStyles={customStyles}
        />
      </div>
    </>
  );
};

export default AllWokflows;
