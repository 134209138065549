//import Account from 'components/Users/Account'
import Account from 'components/Account'
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper'
import React from 'react'
import ErrorBoundary from 'components/ErrorBoundary'




const AccountsPage = () => {
  return (

<ErrorBoundary>
   
    <BreadcrumbsWrapper />
    <Account />
    </ErrorBoundary>

    
  )
}




export default AccountsPage