import './App.css';
import { Routes, Route } from 'react-router-dom';
import { z } from 'zod';
import React from 'react';
import {
    login,
    listSegments,
    home,
    integration,
    campaigns,
    emailTemplates,
    emailEditor,
    workflow,
    previewEmail,
    workflowReport,
    campaignReport,
    create,
    id,
    nestedId,
    logs,
    filters,
    segmentDefinition,
    view,
    matches,
    add,
    filter,
    edit,
    upload,
    fields,
    list,
    eventLogs,
    reports,
    analytics,
    storeReports,
    calendar,
    subscribersProfile,
    cartIntegration,
    scripts,
    google,
    miscellaneous,
    workflowReports,
    campaignReports,
    users,
    account
} from 'services/constant/routes';
import Dashboard from 'pages/Dashboards';
import SubscriberPage from 'pages/Subscribers';
import EmailEditor from 'pages/Email';
import Editor from 'components/Email';
import Reports from 'pages/Reports';
import ProtectedRoute from 'components/ProtectedRoute';
import Login from 'pages/Login';
import ListSegment from 'pages/ListSegments';
import List from 'components/List-Segments/Body/List/index';
import Integration from 'pages/Integration';
import Campaigns from 'pages/Campaigns';
import EmailTemplates from 'pages/EmailTemplates';
import NewCampaign from 'pages/NewCampaign';
import EditCampaign from 'pages/NewCampaign';
import EditWorkflows from 'components/Workflow/Body/Workflows/Workflow';
import CreateWorkflows from 'components/Workflow/Body/Workflows/Workflow';
import CreateSegment from 'components/List-Segments/Body/Segments/index';
import EditSegment from 'components/List-Segments/Body/Segments/index';
import ErrorBoundary from 'components/ErrorBoundary';
import Workflow from 'pages/Workflow';
import PreviewEmail from 'pages/PreviewEmail';
import SingleWorkflowReport from 'pages/SingleWorkflowReport';
import Workflows from 'components/Workflow/Body';
import SingleCampaignReport from 'pages/SingleCampaignReport';
import WorkflowLogs from 'components/Workflow/Body/Workflows/WorkflowLogs';
import ExecutionLogs from 'components/Workflow/Body/Workflows/ExecutionLogs';
import Subscribers from 'components/Subscribers/Subscribers';
import Filter from 'components/List-Segments/Body/Filter/Filter';
import AddFilterForm from 'components/List-Segments/Body/Filter/AddFilterForm';
import EditFilters from 'components/List-Segments/Body/Filter/EditFilters';
import SegmentDefinition from 'components/List-Segments/Body/Segments/SegmentDefinition';
import ViewFilter from 'components/List-Segments/Body/Filter/ViewFilter';
import FilterSubscribers from 'components/List-Segments/Body/Filter/FilterSubscribers';
import NewList from 'components/List-Segments/Body/List/NewList';
import EditList from 'components/List-Segments/Body/List/NewList';
import AddList from 'components/List-Segments/Body/List/AddList';
import UploadFilter from 'components/List-Segments/Body/List/UploadFilter';
import EmailCreationSteps from 'components/Email/EmailCreationSteps';
import ViewSubscriber from 'components/Subscribers/ViewSubscriber';
import SubscriberForm from 'components/Subscribers/SubscribersForm';
import UploadSubscribers from 'components/Subscribers/UploadSubscribers';
import Fields from 'components/Subscribers/Fields';
import EditField from 'components/Subscribers/EditField';
import EventLogs from 'pages/EventLogs';
import Analytics from 'components/DashboardReports/Body/Analytics';
import StoreReport from 'components/DashboardReports/Body/Store-Report';
import Calendar from 'components/DashboardReports/Body/Calendar';
import Cart from 'components/Integration/Body/Cart';
import Script from 'components/Integration/Body/Scripts';
import GoogleAnalytics from 'components/Integration/Body/Google-Analytics';
import Miscellaneous from 'components/Integration/Body/Miscellaneous';
import WorkflowReports from 'pages/WorkflowReports';
import CampaignReports from 'pages/CampaignReports';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Users from 'pages/users';
import User from 'components/Users/user';
import AccountsPage from 'pages/AccountsPage';



const toastSchema = z.object({ success: z.boolean(), error: z.boolean() });
type ToastType = z.infer<typeof toastSchema>;

export const Toastify = (text: string, type: string, id: string) => {
    toast[type as keyof ToastType](text, { toastId: `${id}` });
};

const App = () => {
    return (
        <ErrorBoundary>
            <ToastContainer />
            <Routes>
                <Route path={login} element={<Login />} />                
                
                <Route element={<ProtectedRoute />}>
                    <Route path={home}>
                        <Route index element={<Dashboard />} />
                    </Route>

                    <Route path={users} >
                        <Route index element={<Users />} />
                        <Route path={id} element={<User />} />
                    </Route>

                    <Route path={account}>
                        <Route index element = {<AccountsPage />} />

                    </Route>

                    <Route path={reports}>
                        <Route index element={<Reports />} />
                        <Route path={analytics} element={<Analytics />} />
                        <Route path={storeReports} element={<StoreReport />} />
                    </Route>




                    <Route path={listSegments}>
                        <Route index element={<ListSegment />} />
                        <Route path={list}>
                            <Route index element={<List />} />
                            <Route path={create} element={<NewList />} />
                            <Route path={id} element={<EditList />} />
                            <Route path={add}>
                                <Route path={id}>
                                    <Route index element={<AddList />} />
                                    <Route
                                        path={filter}
                                        element={<UploadFilter />}
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route path={filters}>
                            <Route index element={<Filter />} />
                            <Route path={create} element={<AddFilterForm />} />
                            <Route path={id} element={<EditFilters />} />
                            <Route path={view}>
                                <Route path={id} element={<ViewFilter />} />
                            </Route>
                            <Route path={matches}>
                                <Route
                                    path={id}
                                    element={<FilterSubscribers />}
                                />
                            </Route>
                        </Route>
                        <Route path={segmentDefinition}>
                            <Route index element={<SegmentDefinition />} />
                            <Route path={create} element={<CreateSegment />} />
                            <Route path={id} element={<EditSegment />} />
                        </Route>
                    </Route>





                    <Route path={subscribersProfile} element={<SubscriberPage />}>
                        <Route index element={<Subscribers />} />
                        <Route path={view}>
                            <Route path={id} element={<ViewSubscriber />} />
                        </Route>
                        <Route path={edit}>
                            <Route path={id} element={<SubscriberForm />} />
                        </Route>
                        <Route path={upload}>
                            <Route index element={<UploadSubscribers />} />
                            <Route
                                path={create}
                                element={<SubscriberForm />}
                            />
                        </Route>
                        <Route path={fields}>
                            <Route index element={<Fields />} />
                            <Route path={edit}>
                                <Route path={id} element={<EditField />} />
                            </Route>
                        </Route>
                    </Route>



                    
                    <Route path={integration}>
                        <Route index element={<Integration />} />
                        <Route path={cartIntegration} element={<Cart />} />
                        <Route path={scripts} element={<Script />} />
                        <Route path={google} element={<GoogleAnalytics />} />
                        <Route
                            path={miscellaneous}
                            element={<Miscellaneous />}
                        />
                    </Route>


                    <Route path={campaigns}>
                        <Route index element={<Campaigns />} />

                        <Route path={calendar} element={<Calendar />} />
                        <Route path={create} element={<NewCampaign />}>
                            <Route path={id} element={<EditCampaign />} />
                        </Route>
                    </Route>


                    <Route path={emailTemplates} element={<EmailTemplates />} />


                    <Route path={emailEditor} element={<EmailEditor />}>
                        <Route index element={<Editor />} />
                        <Route path={create} element={<EmailCreationSteps />} />
                        <Route path={edit}>
                            <Route path={id} element={<Editor />} />
                        </Route>
                    </Route>


                    
                    <Route path={workflow} element={<Workflow />}>
                        <Route index element={<Workflows />} />
                        <Route path={create} element={<CreateWorkflows />} />
                        <Route path={id} element={<EditWorkflows />} />
                        <Route path={logs}>
                            <Route path={id}>
                                <Route index element={<WorkflowLogs />} />
                                <Route
                                    path={nestedId}
                                    element={<ExecutionLogs />}
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route path={previewEmail}>
                        <Route index path={id} element={<PreviewEmail />} />
                    </Route>
                    <Route path={workflowReport}>
                        <Route path={id} element={<SingleWorkflowReport />} />
                    </Route>
                    <Route path={campaignReport}>
                        <Route path={id} element={<SingleCampaignReport />} />
                    </Route>
                    <Route path={reports}>
                        <Route
                            path={workflowReports}
                            element={<WorkflowReports />}
                        />
                        <Route
                            path={campaignReports}
                            element={<CampaignReports />}
                        />
                    </Route>
                    <Route path={eventLogs} element={<EventLogs />} />
                </Route>
            </Routes>
        </ErrorBoundary>
    );
};
export default App;
