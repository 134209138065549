import Wrapper from 'components/Wrapper'
import React, { FC } from 'react'
import { AccountSettingsType } from '.';


interface Props {
  showEditing: (attribute: boolean)=>void;
  accountSettings?: AccountSettingsType;

  }


const Settings:FC<Props> = ({showEditing, accountSettings}) => {
  return (
    <div className="flex justify-evenly mx-4">
      <Wrapper parentClass="md:w-[100%]">
        <>
          <div className="flex items-center  justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#5270f3] text-sm font-bold"></p>
            <p className="px-4 py-2 text-sm  text-blue-600  underline cursor-pointer" onClick={()=>{showEditing(true)}}> Edit</p>

          </div>

          <div className="flex items-center border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium">
              Owner
            </p>
            <p className="px-4 py-2 text-sm text-[#878A99]">{accountSettings?.owner}</p>
          </div>

          <div className="flex items-center  border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium">
              Time Zone
            </p>
            {
              <p className="px-4 py-2 text-sm text-[#878A99]">
                {accountSettings?.timezone}
              </p>
            }
          </div>

          <div className="flex items-center  border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium">
              Social Bar
            </p>
            {
              <p className="px-4 py-2 text-sm text-[#878A99]">
                {accountSettings?.showSocialBar===true?"Display the social sharing bar in blasts":"Do not display the social sharing bar in blasts"}
              </p>
            }
          </div>
          <div className="flex items-center  border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium">
              Message Encoding
            </p>
            {
              <p className="px-4 py-2 text-sm text-[#878A99]">
                {accountSettings?.messageEncoding}
              </p>
            }
          </div>
          <div className="flex items-center  border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium">
              Google Analytics
            </p>
            {
              <p className="px-4 py-2 text-sm text-[#878A99]">
                {accountSettings?.googleAnalytics===true? "Automatically add Google Analytics to new blasts":""}
              </p>
            }
          </div>
        
        </>
      </Wrapper>
    </div>
   
  );
}

export default Settings