import Cookies from 'js-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { login } from 'services/constant/routes';
import MainLayout from 'MainLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import { ThemeContextProvider } from 'store/ThemeContext';
import React, { useEffect } from 'react';
import { UserContextProvider } from 'store/UserContext';
import AuthenticationError from './AuthenticationError';

const ProtectedRoute = () => {
    const { REACT_APP_LOGIN_COOKIE } = process.env;
    const cookie = Cookies.get(REACT_APP_LOGIN_COOKIE);
    const { pathname } = useLocation();

    console.log(REACT_APP_LOGIN_COOKIE);
    useEffect(() => {
        const element = document.getElementById(
            'webpack-dev-server-client-overlay-div'
        );

        if (element) {
            element.style.display = 'none';
        }

        if (pathname !== '/email') {
            const libSrc =
                'https://plugins.stripo.email/static/latest/assets/js/LAB.min.js';

            const libScriptTags = document.querySelectorAll(
                'script[src="' + libSrc + '"]'
            );

            libScriptTags.forEach((scriptTag) => {
                scriptTag.remove();
            });

            const targetSrc =
                'https://plugins.stripo.email/static/latest/stripo.js';
            const scriptTags = document.querySelectorAll(
                'script[src="' + targetSrc + '"]'
            );

            scriptTags.forEach((scriptTag) => {
                scriptTag.remove();
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window.StripoApi) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.StripoApi.stop();
            }
        }
    }, [pathname]);
    
    console.log('cookie is : ' , cookie)

    return cookie ? (   
        <ErrorBoundary>
            <UserContextProvider>
                <AuthenticationError>
                <ThemeContextProvider>
                    <MainLayout>
                        <Outlet />
                    </MainLayout>
                </ThemeContextProvider>
                </AuthenticationError>
            </UserContextProvider>
        </ErrorBoundary>
    ) : (
        <Navigate to={login} />
    );
};
export default ProtectedRoute;
