import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import Forms from './Forms';
import ListSegments from './ListSegments';
import Schedule from './Schedule';
import DeliveryOptions from './DeliveryOptions';
import ErrorBoundary from 'components/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { getNewCampaignData, getSubscribersCount } from 'services/apiHandlers/Campaigns/Emails';
import { getFilters } from 'services/apiHandlers/List-Segments/Filter';
import {  getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { FilterResponseData } from 'components/List-Segments/Body/Filter/Types';
import {
  getAllSenders,
  getMailMerge,
} from 'services/apiHandlers/Campaigns/CampaignInformation';
import { getBlasts } from 'services/apiHandlers/Campaigns/CampaignInformation';
import { getcampaignEmails } from 'services/apiHandlers/Campaigns/Emails';
import {
  SenderResponse,
  SenderType,
  Mail,
  MailMergeResponse,
  Option,
  BlastResponse,
  CampaignEmail,
  CampaignResponse,
  SubscriberCountResponse
} from './Types';
import { z } from 'zod';
import { CampaignContext } from 'store/CampaignContext';
import Wrapper from 'components/Wrapper';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import SaveCampaign from './SaveCampaign';
import Sender from './Sender';
import Reply from './Reply';
import SubjectForm from './SubjectForm';
import FinalInformation from './FinalInformation';
import CampaignCreated from './CampaignCreated';
import BackButton from './BackButton';
import NextButton from './NextButton';
import CampaignSteps from './CampaignSteps';

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
  active_people_count: z.string()
});
const getListResponseSchema = z.object({
  data: z.object({
    lists: z.array(getListResponseDataSchema),
  }),
  status: z.number(),
});
export type GetListResponse = z.infer<typeof getListResponseSchema>;
export type ListType = z.infer<typeof getListResponseDataSchema>;

const newCampaignDataSchema = z.object({
  finder_id: z.number(),
  hide_continue_button: z.boolean(),
  campaign_source: z.string(),
  draft_changed: z.boolean(),
  franchise_enabled: z.boolean(),
  no_subscribers: z.boolean(),
  personDbId: z.number(),
  resold: z.boolean(),
  rss_enabled: z.boolean(),
  rss_included: z.boolean(),
  buy_more_quota_link: z.string(),
  upgrade_account_link: z.string(),
  preview_link: z.string(),
  account_tracking_hash: z.string(),
  default_owner: z.object({
    owner: z.object({
      account_id: z.number(),
      city: z.string(),
      country: z.string(),
      created_at: z.string(),
      custom_field_values: z.string(),
      deleted_at: z.string(),
      franchise_code: z.string(),
      franchise_id: z.number(),
      from_email_address: z.string(),
      from_name: z.string(),
      id: z.number(),
      name: z.string(),
      owner_type: z.string(),
      state: z.string(),
      street_address_1: z.string(),
      street_address_2: z.string(),
      tracking_hash: z.string(),
      updated_at: z.string(),
      zip: z.string(),
    }),
  }),
  default_owner_valid: z.boolean(),
  include_social_bar: z.boolean(),
  share_description: z.string(),
  use_google_analytics: z.boolean(),
});
const getNewCampaignResponseSchema = z.object({
  data: newCampaignDataSchema,
  status: z.number(),
});
type GetNewCampaignDataResponse = z.infer<typeof getNewCampaignResponseSchema>;
const CampaignInformation: FC = () => {
  const campaignCtx = useContext(CampaignContext);
  const { id } = useParams();
  const [hideReply, setHideReply] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [campaignCreated, setCampaignCreated] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('Select a Template');
  const [selectedFilter, setSelectedFilter] = useState<string>('')
  const [showSenderError, setShowSenderError] = useState<boolean>(false);
  const [showSubjectError, setShowSubjectError] = useState<boolean>(false);
  const [showEmailTemplateError, setShowEmailTemplateError] = useState<boolean>(false);
  const [showTriggerError, setShowTriggerError] = useState<boolean>(false);
  const [activeCount, setActiveCount] = useState<number>();
  // List&Segment
  const [filterOptions, setFilterOptions] = useState<Array<Option>>([]);
  const [listOptions, setListOptions] = useState<Array<Option>>([]);
  const [list, setList] = useState<Array<Option>>([]);
  const [subscribers, setSubscribers] = useState<Array<Option>>([]);
  // Sender
  const [senders, setSenders] = useState<Array<SenderType>>([]);
  //SubjectForm
  const [mails, setMails] = useState<Array<Mail>>([]);
  const [mergeOptions, setMergeOptions] = useState<Array<Option>>([]);
  const [mergeMail, setMergeMail] = useState<string>('');
  //Delivery Options
  const [blasts, setBlasts] = useState<Array<string>>([]);
  const [emailOptions, setEmailOptions] = useState<Array<Option>>([]);
  const [campaigns, setCampaigns] = useState<Array<CampaignEmail>>([]);

  const getSenderData = async () => {
    try {
      // Sender
      const getSendersresponse = (await getAllSenders()) as SenderResponse;
      if (getSendersresponse.status === 200) {
        setSenders(getSendersresponse.data.blast_addresses);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getBlastData = async () => {
    try {
      // Delivery Options (Blasts)
      const getTagBlastsResponse = (await getBlasts()) as BlastResponse;
      if (getTagBlastsResponse.status === 200) {
        setBlasts(getTagBlastsResponse.data.tags);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getData = async () => {
    try {

      const subscriberCountResponse = (await getSubscribersCount() as SubscriberCountResponse);
      if (subscriberCountResponse.status === 200) {
        setActiveCount(subscriberCountResponse.data.active_people_count)
      }
      //Delivery Options
      const getEmailTemplateResponse =
        (await getcampaignEmails()) as CampaignResponse;
      if (getEmailTemplateResponse.status === 200) {
        let tempMails = [];
        tempMails = getEmailTemplateResponse.data.emails.map((mail) => {
          return { label: mail.name, value: mail.id.toString() };
        });
        if (id) {
          const tempSelected = getEmailTemplateResponse.data.emails.find(
            (email) => {
              return email.id === parseInt(id);
            }
          );
          setSelectedTemplate(tempSelected?.name || 'Select a template');
        }
        setEmailOptions(tempMails);
        setCampaigns(getEmailTemplateResponse.data.emails);
      }

      //SubjectForm
      const getSubjectFormDataresponse =
        (await getMailMerge()) as MailMergeResponse;
      if (getSubjectFormDataresponse.status === 200) {
        setMails(getSubjectFormDataresponse.data.fields);
        setMergeMail(getSubjectFormDataresponse.data.fields[0].tag);
        const options = getSubjectFormDataresponse.data.fields.map((mail) => {
          return { value: mail.name, label: mail.name };
        });
        setMergeOptions(options);
      }

      // List&Segment

      const getListsresponse = (await getAllList()) as GetListResponse;
      if (getListsresponse.status === 200) {
        console.log('Check Lists: ',getListsresponse.data)
        const tempListOptions = getListsresponse.data.lists.map(lists => {
            return {
              value: lists.id.toString(),
              label: lists.name.concat(` (${lists.active_people_count})`),
            };
        });       
 
        setListOptions(tempListOptions);
        
        const includedIds: number[] = campaignCtx?.campaignData?.include_lists
          ? campaignCtx?.campaignData?.include_lists.length > 0
            ? campaignCtx?.campaignData?.include_lists
            : []
          : [];
        const excludeIds: number[] = campaignCtx?.campaignData?.exclude_lists
          ? campaignCtx?.campaignData?.exclude_lists.length > 0
            ? campaignCtx?.campaignData?.exclude_lists
            : []
          : [];
        const tempSendto = getListsresponse.data.lists.filter((list) =>
          includedIds.includes(list.id)
        );
        const tempExcludeList = getListsresponse.data.lists.filter((list) =>
          excludeIds.includes(list.id)
        );
        const sendTo = tempSendto.map((lists) => {
          return { label: lists.name, value: lists.id.toString() };
        });
        const ExcludedList = tempExcludeList.map((lists) => {
          return { label: lists.name, value: lists.id.toString() };
        });
        setList(ExcludedList);
        setSubscribers(sendTo);
      }

      //Filters
      const filterResponse = (await getFilters()) as FilterResponseData;
      if (filterResponse.status === 200) {
        const tempFilterOptions = filterResponse.data.filters.map((filter) => {
          return {
            label: filter.name,
            value: filter.id.toString(),
          };
        });
        setFilterOptions(tempFilterOptions);
        // setFilters(filterResponse.data.filters);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const getNewCampaignDataHandler = async (id: string) => {
    try {
      const getNewCampaignDataResponse = (await getNewCampaignData(
        id
      )) as GetNewCampaignDataResponse;
      if (getNewCampaignDataResponse.status === 200) {
        const {
          finder_id,
          campaign_source,
          share_description,
          include_social_bar,
          use_google_analytics,
        } = getNewCampaignDataResponse.data;
        if (campaignCtx?.campaignData) {
          campaignCtx?.onUpdateCampaignData({
            ...campaignCtx.campaignData,
            finder_id: finder_id,
            email_id: parseInt(id),
          });
        }
        campaignCtx?.onChangeInitialData({
          campaignData: {
            utm_campaign: campaignCtx.campaignData?.utm_campaign || '',
            utm_content: campaignCtx.campaignData?.utm_content || '',
            utm_medium: campaignCtx.campaignData?.utm_medium || '',
            utm_source: campaign_source,
          },
          description: share_description,
          socialBar: include_social_bar,
          googleAnalytics: use_google_analytics,
          trackClick: false,
        });
      }
    } catch (error) {
      console.log('erorr is  : ', error);
    }
  };
  const handleNextStep = () => {
    if (currentStep === 2 && !campaignCtx?.campaignData?.from.id) {
      setShowSenderError(true);
    } else if (currentStep === 3 && !campaignCtx?.campaignData?.subject) {
      setShowSubjectError(true);
    } else if (currentStep == 5 && !campaignCtx?.campaignData?.email_id) {
      setShowEmailTemplateError(true);
    } else if (
      currentStep == 5 &&
      !campaignCtx?.campaignData?.triggerName &&
      campaignCtx?.campaignData?.schedule_type !== 'now'
    ) {
      setShowTriggerError(true);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  const handleStepClick = (stepId: number) => {
    if (stepId === 3 && !campaignCtx?.campaignData?.from.id) {
      setShowSenderError(true);
    } else if (stepId === 4 && !campaignCtx?.campaignData?.subject) {
      setShowSubjectError(true);
    } else if (stepId == 6 && !campaignCtx?.campaignData?.email_id) {
      setShowEmailTemplateError(true);
    } else if (
      stepId == 6 &&
      !campaignCtx?.campaignData?.triggerName &&
      campaignCtx?.campaignData?.schedule_type !== 'now'
    ) {
      setShowTriggerError(true);
    } else {
      setCurrentStep(stepId);
    }
  };
  useEffect(() => {
    const emailId = id;
    if (emailId) {
      getNewCampaignDataHandler(emailId);
    }
    getData();
    getSenderData();
    getBlastData();
  }, []);

  const FormComponents: { [key: number]: ReactElement } = {
    1: (
      <ListSegments
        filterOptions={filterOptions}
        listOptions={listOptions}
        list={list}
        setList={setList}
        subscribers={subscribers}
        setSubscribers={setSubscribers}
        setSelectedFilter={setSelectedFilter}
      />

    ),
    2: (
      <div className="">
        <Sender
          getData={getSenderData}
          senders={senders}
          hideReplySection={(show) => setHideReply(show)}
          setShowSenderError={setShowSenderError}
          showSenderError={showSenderError}
        />
        {!hideReply && <Reply />}
      </div>
    ),
    3: (
      <SubjectForm
        mails={mails}
        mergeOptions={mergeOptions}
        mergeMail={mergeMail}
        setMergeMail={setMergeMail}
        setShowSubjectError={setShowSubjectError}
        showSubjectError={showSubjectError}
      />
    ),
    4: <DeliveryOptions />,
    5: (
      <>
        <Forms
          showEmailTemplateError={showEmailTemplateError}
          setShowEmailTemplateError={setShowEmailTemplateError}
          onEmailChange={(id: string) => getNewCampaignDataHandler(id)}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          getData={getBlastData}
          blasts={blasts}
          setBlasts={setBlasts}
          emailOptions={emailOptions}
          campaigns={campaigns}
        />
        <Schedule
          showTriggerError={showTriggerError}
          setShowTriggerError={setShowTriggerError}
        />
      </>
    ),
    6: <FinalInformation selectedTemplate={selectedTemplate} selectedFilter={selectedFilter} />,
  };

  console.log('Check CampaignData: ', campaignCtx?.campaignData)
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper />
      <div className=" md:px-4 px-3 py-5 font-inter h-full min-h-[76vh]">
        <Wrapper>
          <>
            <h1 className="p-4 border-b text-base font-medium leading-5 text-[#495057] dark:text-white">
              Create Campaign
            </h1>
            <div className=" px-4 pt-8 pb-10">
              <div className="flex sm:flex-row flex-col">
                <div className='w-full max-w-[250px] xl:max-w-[300px] 2xl:max-w-[350px]'>
                  <ul className="w-[100%] sm:mb-0 mb-8">
                    {/* TO_FIX Make a seperate Component for Steps */}
                    <CampaignSteps
                      currentStep={currentStep}
                      handleStepClick={handleStepClick}
                    />
                  </ul>
                </div>
                {!campaignCreated && (
                  // TO_FIX make a object for these components 2xl:w-[40%] xl:w-[60%] w-[80%]
                  <div className="sm:mx-6 w-full 2xl:max-w-[1000px] max-w-[600px] sm:px-6 flex flex-col justify-between">
                    <div>{FormComponents[currentStep]}</div>
                    <div className=" flex flex-wrap xl:justify-between justify-center">
                      {currentStep !== 1 && (
                        <BackButton
                          currentStep={currentStep}
                          setCurrentStep={setCurrentStep}
                        />
                      )}
                      {currentStep !== 6 && (
                        <NextButton
                          currentStep={currentStep}
                          handleNextStep={handleNextStep}
                        />
                      )}
                      {currentStep == 6 && (
                        <SaveCampaign
                          setCampaignCreated={(created: boolean) =>
                            setCampaignCreated(created)
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="lg:mx-6 xl:w-[40%] lg:px-6 flex items-center">
                  {campaignCreated && <CampaignCreated />}
                </div>
              </div>
              <div>
                Subscriber Summary:
                <div>
                  {activeCount || '-'}
                </div>
              </div>
            </div>
          </>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default CampaignInformation;
