//-------------------  List And Segments --------------------------//
//authentication
export const ACCOUNT_LOGIN = '/account/api_login';
export const ACCOUNT_LOGOUT = '/account/logout';

//segment
export const CREATE_SEGMENT =
  '/apps/insights/v1/segments_with_elastic_search/save_segment';
export const GET_SEGMENT =
  '/apps/insights/v1/segments_with_elastic_search/edit_segment?name=';
export const GET_ALL_SEGMENTS =
  '/apps/insights/v1/segments_with_elastic_search/get_segments';
export const DELETE_SEGMENT =
  'apps/insights/v1/segments_with_elastic_search/delete_segment';
export const DISABLE_SEGMENT =
  '/apps/insights/v1/segments_with_elastic_search/change_segment_status';
export const GET_UPDATED_SEGMENT =
  'apps/insights/v1/segments_with_elastic_search/get_updated_segment?name=';

//list
export const GET_LIST = '/api/v2/lists?page=';
export const CREATE_LIST = '/audiences/1/lists.json';
export const UPDATE_LIST = '/audiences/1/lists/';
export const DELETE_LIST = '/api/v2/list/';
export const GET_FIELDS = '/audiences/1/fields.xml';
export const GET_SINGLE_LIST = '/audiences/1/lists/';
export const ADD_MEMBERS_USING_FILTER =
  '/audience/1/list/{{listId}}/add_members?filter_id=';
export const GET_ALL_LIST = '/api/v2/lists/all';
export const GET_ALL_LISTS = '/audiences/1/lists.json';
export const GET_FILTER_LIST = 'api/v2/lists?'; //filter_value=anis&page=1&per_page=10
// Subscriber
export const GET_ALL_SUBSCRIBERS = '/audiences/1/people.xml?';
export const GET_SUBSCRIBERS = '/api/v2/subscribers/get?';
export const CREATE_SUBSCRIBER = '/audiences/1/people';
export const GET_SUBSCRIBER_BY_ID = '/api/v2/subscriber/'; // append id with .xml
export const GET_SUBSCRIBER_EVENTS =
  '/apps/insights/v1/identified_events/{{MAIL}}?order=descending';
export const GET_SUBSCRIBER_TAGS = '/api/v2/subscriber/tags?id={{EMAIL}}';
export const GET_EDIT_SUBSCRIBER = 'api/v2/subscriber/';
//export const GET_SUBSCRIBER_LIST = '/audiences/1/people.json?per_page=10&page=1&list_id=9&opt_status=active&email_address=dekh'

//field
export const CREATE_FIELD = '/api/v2/create_field';
export const DELETE_FIELD = '/audiences/1/fields/{{ID}}.xml';
export const UPDATE_FIELD = '/audiences/1/fields/{{ID}}.xml';
export const GET_SUBSCRIBER_MEMBERSHIP =
  '/audiences/1/people/{{ID}}/memberships.xml?page=1';
export const GET_SUBSCRIBER_BY_LIST_ID =
  '/audiences/1/lists/{{ID}}/people.xml?';
export const GET_SUBSCRIBER_BY_EMAIL = '/audiences/1/people/{{ID}}';
export const GET_SINGLE_FIELD = '/audiences/1/fields/{{ID}}.xml';
export const GET_FIELD_OPTIONS = '/api/v2/fields/{{ID}}/options';

//filter
export const GET_ALL_FILTERS = '/api/v2/filters/get?';
export const GET_FILTERS = '/audiences/1/filters.json';
export const CREATE_FILTER = '/api/v2/segments/create';
export const DELETE_FILTER = '/audiences/1/filters/{{ID}}.xml';
export const GET_FILTER = '/audiences/1/filters/';
export const GET_FILTER_BY_ID = '/api/v2/segments/';
export const SWAP_BLOCK = '/audience/segment_block/swap_logic_operator/';
export const SWAP_RULE = '/api/v2/segments/swap_rule_logic/';
export const DELETE_RULE = '/audience/segment_rule/destroy/';
export const ADD_RULE = '/api/v2/segments/rule/';
export const ADD_BLOCK = '/api/v2/segments/add_block/';
export const UPDATE_FILTER = '/api/v2/segments/';
export const GET_COUNT_FILTER = '/audience/1/segment/count/{{ID}}?match_type=';
export const GET_FILTER_SUBSCRIBERS = '/api/v2/segments/match/';
export const EDIT_FILTER = '/audiences/1/filters/{{ID}}.xml';
export const ADD_FIELD_OPTION = '/api/v2/fields/subscriber/add_option/';
export const UPDATE_FIELD_OPTION = '/api/v2/fields/subscriber/set_option_name/';
export const DELETE_FIELD_OPTION = '/api/v2/fields/subscriber/delete_option/';
export const SORT_FIELD_OPTIONS = '/api/v2/fields/subscriber/sort_options/';

// -----------------------------------------------------------------//

// ----------------------- Integrations ----------------------------//
//google-Analaytic-Settings
export const GET_GOOGLE_ANALYTICS =
  '/apps/insights/v1/account_settings/google_analytics';
export const UPDATE_GOOGLE_ANALYTICS =
  '/apps/insights/v1/account_settings/google_analytics';

//scripts
export const GET_ONLOAD_SCRIPTS = '/apps/insights/v1/onload_scripts';
export const UPDATE_WORKFLOW_SCRIPT =
  '/apps/insights/v1/workflow_scripts/{{ID}}';
export const UPDATE_ONLOAD_SCRIPT = '/apps/insights/v1/onload_scripts/{{ID}}';
export const CREATE_WORKFLOW_SCRIPT = '/apps/insights/v1/workflow_scripts';
export const CREATE_ONLOAD_SCRIPT = '/apps/insights/v1/onload_scripts';
export const DELETE_WORKFLOW_SCRIPT =
  '/apps/insights/v1/workflow_scripts/{{ID}}';
export const DELETE_ONLOAD_SCRIPT = '/apps/insights/v1/onload_scripts/{{ID}}';

//Miscellaneous
export const GET_DEFAULT_CONVERSION_PERIOD =
  '/apps/insights/v1/account_settings/default_conversion_period';
export const GET_FREQUENCY_LIMIT =
  '/apps/insights/v1/account_settings/workflow_action_frequency_limit';
export const GET_EMAIL_EVENTS = '/api/v2/system_event_configuration';
export const GET_CONNECTED_SERVICES =
  '/apps/insights/v1/email_provider_configurations';
export const EDIT_CONNECTED_SERVICES =
  '/apps/insights/v1/email_provider_configurations';
export const GET_EMAIL_SENDERS = '/apps/insights/v1/senders';
export const GET_BLAST_CONVERSION_EVENT_NAMES = '/apps/insights/v1/event_names';
export const GET_BLAST_CONVERSION_EVENT =
  '/apps/insights/v1/ss_blast_conversion_event';
export const GET_WORKFLOW_SCRIPTS = '/apps/insights/v1/workflow_scripts';
export const UPDATE_CONVERSION_PERIOD =
  '/apps/insights/v1/account_settings/default_conversion_period';
export const UPDATE_WORKFLOW_FREQUENCY =
  '/apps/insights/v1/account_settings/workflow_action_frequency_limit';
export const UPDATE_EMAIL_SYSTEM_EVENTS = '/api/v2/system_event_configuration';
export const UPDATE_BLAST_CONVERSION_EVENT =
  '/apps/insights/v1/ss_blast_conversion_event';
export const CREATE_CONNECTED_SERVICES =
  '/apps/insights/v1/email_provider_configurations';
export const CREATE_EMAIL_SENDER = '/apps/insights/v1/senders';
export const DELETE_EMAIL_SENDER = '/apps/insights/v1/senders/{{ID}}';
export const EDIT_EMAIL_SENDER = '/apps/insights/v1/senders/';

//cart-integration
export const VALIDATE_INTEGRATION =
  '/apps/insights/v1/cart_integrations/validate';
export const CREATE_INTEGRATION = '/apps/insights/v1/cart_integrations';
export const GET_INTEGRATIONS = '/apps/insights/v1/cart_integrations';

//--------------------------DASHBOARD------------------------------------//
//Analytics
export const GET_WORKFLOW_REPORTS = '/apps/insights/v1/workflow_reports?';
export const GET_BLAST_BY_STATUS = '/report/blasts?';
export const GET_ALL_BLASTS = '/report/blasts';
export const GET_BLAST_MONETARY_VALUE =
  '/apps/insights/v1/ss_blast_conversion_report';
export const GET_WEEKLY_ROI_REPORT = '/apps/insights/v1/weekly_roi/get_report';
export const GET_ALL_WORKFLOWS = '/apps/insights/v1/triggers';
export const GET_SUBSCRIBER_SUMMARY = '/audience/1/subscriber/report?';
export const GET_SUBSCRIBERS_COUNT = '/audiences/1/people/active_people_count.json'
export const GET_SINGLE_CAMPAIGN = '/api/v2/report/blast?id=';
export const GET_REPORT_QOUTA = '/api/v2/report/quota';
export const GET_CSV_EXPORT = '/apps/insights/v1/email_reports?';
export const GET_LATEST_SUBSCRIBERS = '/api/v2/subscribers/latest';
export const GET_WORKFLOW_CONVERSION_REPORTS =
  '/apps/insights/v1/conversion_reports?endDate={{END_DATE}}&startDate={{START_DATE}}&switchCountsTable=false';
export const GET_PERFORMANCE_REPORTS =
  '/apps/insights/v1/results_dashboard?startDate={{START_DATE}}&endDate={{END_DATE}}&accountIds={{ID}}';

//Store Report
export const GET_ORDER_REVENUE_REPORT =
  '/apps/insights/v1/order_revenue_report?';

//-------------------------campaigns------------------------------------//
export const GET_FILTERED_CAMPAIGNS = 'api/v2/report/blasts?';
export const GET_campaign_EMAILS = '/api/v2/email/get';
export const GET_SENDERS = '/blast_address.json';
export const CREATE_SENDERS = '/blast_address';
export const GET_BLASTS = '/api/v2/blast_tags';
export const CREATE_BLASTS = '/api/v2/blast_tags';
export const GET_MAIL_MERGE = '/send/new_blast/mail_merge_fields';
export const GET_EMAIL = '/api/v2/emails/';
export const AUTOSAVE_EMAIL = '/email/auto_save';
export const SAVE_EMAIL = '/api/v2/email/save';
export const DELETE_EMAIL = '/api/v2/email/destroy?id=';
export const TEST_EMAIL = '/blasts/test';
export const GET_USERS = '/users.json?reseller_request=false';
export const GET_USER = '/users/{{ID}}.xml';
export const CREATE_NEW_EMAIL = '/api/v2/email/new';
export const COPY_PASTE_HTML = '/api/v2/email/copy_paste';
export const FETCH_HTML = '/api/v2/email/by_url';
export const UPLOAD_FILE = '/api/v2/email/by_file';
export const GET_TEMPLATE = '/api/v2/email/template';
export const GET_CURRENT_USER = '/api/v2/loggedin_user';
export const GET_MAIL_MERGE_OPTIONS = '/api/v2/email/mail_merger';
export const CREATE_CAMPAIGN = '/send/new_blast/schedule';
export const VERIFY_EMAIL =
  '/from_email_addresses/activated?from_email_address=';
export const RESEND_EMAIL_VERIFICATION =
  '/from_email_addresses/resend_activation_email_for_email_address';
export const GET_NEW_CAMPAIGN_DATA = '/api/v2/blast/precreation?email_id=';
export const GET_POPULAR_TAGS = '/api/v2/email/popular_tags';
export const GET_EDITOR_IMAGES = '/email/image_listing';
export const DELETE_EDITOR_IMAGE = '/email/delete_image';
export const UPLOAD_EDITOR_IMAGE = '/email/upload_image';
export const CREATE_TRIGGER = '/api/v2/trigger/create';
export const GET_ALL_STATUS_BLASTS = '/api/v2/report/all_blasts';
export const GET_TEMPLATE_BY_ID = '/api/v2/email/custom_template?id=';
export const DELETE_SENDER = '/blast_address/';
export const SEND_CAMPAIGN_REPORT =
  '/report/blast/summary/single/{{ID}}/send_blast_report';

//-------------------------WORKFLOWS-----------------------------------//
export const GET_SINGLE_WORKFLOW = '/apps/insights/v1/triggers/';
export const GET_EVENT_NAMES = '/apps/insights/v1/event_names';
export const GET_EVENT_ATTRIBUTES = '/apps/insights/v1/event_attribute_names/';
export const UPDATE_WORKFLOW = '/apps/insights/v1/triggers/';
export const GET_TAGS = '/apps/insights/v1/tags';
export const GET_WEBHOOK_EVENTS =
  '/apps/insights/v1/events?anonymous=false&name={{name}}&limit=25&order=descending&initialConditionGroup=';
export const TEST_WEBHOOK =
  '/apps/insights/v1/test_conditional_webhook_requests';
export const SEND_TEST = '/apps/insights/v1/test_webhook_requests';
export const CREATE_WORKFLOW = '/apps/insights/v1/triggers';
export const DELETE_WORKFLOW = '/apps/insights/v1/triggers/';
export const GET_WORKFLOW_LOGS = '/apps/insights/v1/workflows/{{ID}}/log';
export const RESET_EXECUTIONS = '/apps/insights/v1/triggers/{{ID}}/executions';
export const GET_EXECUTION_LOGS = '/apps/insights/workflows/{{ID}}/logs/';
export const GET_CONFIGURATION = '/apps/insights/v1/configuration';
export const GET_WORKFLOW_TRANSACTION_REPORTS =
  '/apps/insights/v1/transactional_email_report';

//-------------------------LOGS-----------------------------------//
export const GET_ALL_LOGS =
  '/apps/insights/v1/events_by_timestamp?startDate={{START}}&endDate={{END}}{{FILTER}}{{NAME}}&order=descending';

//--------------------------REPORTS-------------------------------//
export const START_EXPORT = "/report/blast/summary/single/{{ID}}/start_export_job"
export const CHECK_STATUS = "/report/export/check_export_status"
export const DOWNLOAD_REPORT = "/report/export/stream_export/"
export const ADD_REPORT_MEMBERS = "/report/blast/summary/single/add_members_from_report/"
