import Wrapper from 'components/Wrapper'
import React, { FC } from 'react'
import DownloadIcon from 'assets/Images/Common/downloadIcon.svg';
import { CreditCardInfoType } from '.';


interface Props {
showEditing: (attribute: boolean)=>void;
cardInfo?: CreditCardInfoType;
}

const Billing:FC<Props> = ({showEditing, cardInfo}) => {
    return (
        <Wrapper parentClass="md:w-[40%]">
        <>
        <div className="flex items-center mb-8 border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-black-400 text-xl font-normal">Billing</p>
            <p className="px-4 py-2 text-sm  text-blue-600  underline cursor-pointer" onClick={()=>{showEditing(true)}}> Edit</p>
        </div>
    
          <div className="flex items-center border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium">
              Payment Method
            </p>
            <p className="px-4 py-2 text-sm text-[#878A99]">Credit Card</p>
          </div>
    
    
          <div className="flex flex-col bg-blue-400 m-2 rounded-md">
           <div className='flex align-middle'>
            <img className='h-5 w-5 mt-1 font-medium' src={DownloadIcon}/>
           <p className='text-xxs ml-2 mt-1' >Credit Card</p>
           </div>
            <div className='flex flex-col bg-white m-1 rounded-md'>
            
            <div className='flex justify-between p-1'>
            <p className='text-sm'>**** **** **** {cardInfo?.cardNumber.toString().slice(-4)}</p>

            {/* <p className='text-sm'>{cardInfo?.cardNumber.toString().replace(/(\d{4})/g, '$1 ')}</p> */}


            <p className='text-sm'>{cardInfo?.cardCompany}</p>
            </div>
            
            <div className='flex justify-between p-1'>
            <p className='text-sm'>{cardInfo?.cardHolderName}</p>
            <p className='text-sm'>Expires: {cardInfo?.cardExpiry}</p>
            </div>
            
            </div>
    
            </div>
           
          </>
      </Wrapper>
    
  )
}

export default Billing