import React, { FC } from 'react'
import { CreditCardInfoType } from '.';

interface Props {
  cardInfo?: CreditCardInfoType | undefined
}

const CreditCardEditForm: FC<Props> = ({cardInfo}) => {
    return (
      <>
      <form>
      <div className="flex flex-col justify-between w-full">

        <div className='flex w-full my-3 border-b pb-3'>
        <div className='flex w-1/2 flex-col ' >
        <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
              Card Holder Name
            </label>
            <input
              className="w-4/5 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
              type="text"
              defaultValue={cardInfo?.cardHolderName}
            />
        </div>

        <div className='flex w-1/2 flex-col' >
        <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
              Card Company
            </label>
            <input
              className="w-full placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
              type="text"
              defaultValue={cardInfo?.cardCompany}
              placeholder='Visa , Master Card etc'
            />


        </div>

        </div >




        
        <div className='flex w-full my-3 pr-3 border-b pb-3'>
        <div className='flex w-1/2 flex-col' >
        <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
              Card Number
            </label>
            
            
            <div className='flex w-full justify-between'>
            <input
              className="w-1/5 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-1 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
              type="number"
              maxLength={4}
            />
               <input
              className="w-1/5 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-1 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
            type="number"
            maxLength={4}
            />
               <input
              className="w-1/5 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-1 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
            type="number"
            
            />
               <input
              className="w-1/5 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-1 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
            type="number"
            maxLength={4}
            />
            
            </div>

        </div>

        </div >


        <div className='flex w-full flex-col mt-3 border-b pb-3' >
        <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
              Card Expiry
            </label>


            <div className='flex w-full items-center mt-1'>
            <input
              className=" mr-3 w-1/12 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-1 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
              type="number"
              placeholder='MM'
              
            />
            <label className=" mr-3 text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white " >
                /
            </label>
            <input
              className="w-1/12 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-1 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
              type="number"
              placeholder='YY'
              
            />  
         


            
            </div>

        </div>



        <div className='flex w-full my-3 border-b pb-3'>
        <div className='flex w-1/2 flex-col' >
        <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
              Card CCV
            </label>
            <input
              className="w-1/2 placeholder:font-normal focus:border-primary dark:focus:border-white 
            focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] 
            placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  
            dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]"
              type="number"
              
            />


        </div>

        <div className='flex w-1/2 justify-end items-end' >
        
        <button type='button'  className=' w-2/5 font-nomral text-13 font-medium leading-5 rounded-md px-2  ml-2 over:opacity-90 ease-in duration-300 bg-primary text-white h-9'>
        Update
        </button>
        <button type='button' className=' w-2/5 font-nomral text-13 font-medium leading-5 rounded-md px-2  ml-2 over:opacity-90 ease-in duration-300 bg-primary text-white h-9'>
        Cancel
        </button>
        
        </div>

        </div >
      



         
        </div>
        </form>

      </>
    );

}

export default CreditCardEditForm