import React, { FC, useState } from 'react'
import GoogleAnalyticsForm from './GoogleAnalyticsForm';
import { AccountSettingsType } from '.';


interface Props {
  accountSettings?: AccountSettingsType


}





const AccountSettingsEditForm:FC<Props> = ({accountSettings}) => {
const [showGoogleAnalyticsOptions, setshowGoogleAnalyticsOptions] = useState<boolean>(false);



  return (
    <div className="flex flex-col w-full px-2 py-5">
      
      
      <div className="flex justify-start border-b w-full pb-2">
        <div className='w-2/6'>
        <label>Time Zone</label>
        </div>
        <div className='w-4/6'>
        <select className='text-sm' >
          <option>Time Zone 1</option>
          <option>Time Zone 2</option>
          <option>Time Zone 3</option>
          <option>Time Zone 4</option>
          <option>Time Zone 5</option>
        </select>
        </div>
      </div>
    
    
    <div className=' mt-3 flex justify-start w-full border-b pb-2'>
   <div className='w-2/6'>
    <label>Social Bars</label>
    </div>

    <div className='flex flex-col w-4/6 justify-start'>
        <div className='flex justify-start'>
            <div><input type='radio'></input></div>
            <p className='text-sm ml-2'>Display the social sharing bars in blast</p>
        </div>
        <div className='flex justify-start'>
            <div><input type='radio'></input></div>
            <p className='text-sm ml-2'>Do not display the social sharing bars in blast</p>
        </div>

    </div>

    </div>



    <div className='mt-3 flex justify-start w-full border-b pb-2' >
    
    <div className='w-2/6'>   
    <label>Message Encoding</label>
    </div>

    <div className='flex flex-col w-4/6 justify-start'>
        <div className='flex justify-start'>
            <div><input type='radio'></input></div>
            <p className='text-sm ml-2'>Auto-detect content encoding {`(`}Default{`)`} </p>
        </div>
        <div className='flex justify-start'>
            <div><input type='radio'></input></div>
            <p className='text-sm ml-2'>Always use UTF-8 encoding</p>
        </div>

    </div>
    </div>

    
    
    <div className='mt-3 flex justify-start w-full border-b pb-2' >
    <div className='w-2/6'>
    <label>Google Analytics</label>
    </div>

    <div className='flex flex-col w-4/6 justify-start'>
        <div className='flex justify-start'>
            <div><input type='checkbox' onChange={()=>{setshowGoogleAnalyticsOptions((prev)=>!prev)}}></input></div>
            <p className='text-sm ml-2'>Integrate Google Analytics URL</p>
        </div>
        <p className='text-black-200 text-xxs break-all'>Google Analytics Integration automatically 
            modifies all links in your HTML email with campaign parameters. 
            Your website must have Google Analytics Integrated.</p>
    </div>
</div>
    
{
  showGoogleAnalyticsOptions && 
  <div className='flex flex-col justify-start w-full border-b py-4'>  
  <GoogleAnalyticsForm settings = {accountSettings} />
  </div>
 }
    
    <div className='flex flex-col justify-start w-full mt-3' >
    
    <div className='flex justify-start'>   
    <label>Mailing Address</label>
    </div>

    <div className='flex flex-col w-full justify-start'>

    <div className='flex w-full mt-3'>

    <div className='flex flex-col w-1/2 mr-3'>
    <label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white '>Street 1</label>
    <input type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
            
    

    </div>
    <div className='flex flex-col w-1/2'>
    <label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white '>Street 2</label>
    <input type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
            
    </div>


    </div>


<div className='flex w-full mt-3'>

<div className='flex flex-col w-1/2 mr-3'>
<label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white '>State</label>
<input type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
</div>

<div className='flex flex-col w-1/2'>
<label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white '>City</label>
<input type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
           
</div>


</div>


<div className='flex w-full mt-3'>

<div className='flex flex-col w-1/2 mr-3'>
<label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white '>Postal Code</label>
<input type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
           

</div>
<div className='flex flex-col w-1/2'>
<label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white '>Country</label>
<input type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
           
</div>


</div>

    </div>

    </div>


    </div>
  );
}

export default AccountSettingsEditForm