import React, { useContext, useRef, useState } from 'react';
import Menu from 'assets/Images/svg-images/menu.svg';

import UsersIcon from 'assets/Images/Common/users.svg';
import { logout } from 'services/Authentication';
import {  home, list, listSegments, login, users } from 'services/constant/routes';
import Cookies from 'js-cookie';
import IconButton from 'components/Header/IconButton';
import { Switcher } from 'components/Switcher';
import ErrorBoundary from 'components/ErrorBoundary';
import SearchImage from 'assets/Images/Common/search.png';
import LogoutIcon from 'assets/Images/Common/logoutIcon.svg';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import { UserContext } from 'store/UserContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { Toastify } from 'App';
const responseResult = z.object({ status: z.number() });
type responseStatus = z.infer<typeof responseResult>;
interface Props {
  toggleSidebar: () => void;
}
const Header: React.FC<Props> = ({ toggleSidebar }) => {
  const { pathname } = useLocation();
  const userCtx = useContext(UserContext);
  const outsideRef = useRef(null);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [switchInterface, setSwitchInterface] = useState<boolean>(true)

  const navigate = useNavigate();
  useOutsideClick(outsideRef, () => {
    setProfileDropdown(false);
  });
  const logoutUser = async () => {
    setLoading(true)
    try {
      const logoutResponse = (await logout()) as responseStatus;
      if (logoutResponse.status === 200) {
        Cookies.remove('_meta_session_id_production');
        navigate(login);
      }
    } catch (error) {
      Toastify('Logging out failed', 'error', 'dropdown1');
    }
    setLoading(false)
  };
  const getNameInitials = (name: string | undefined) => {
    const initials = name && name.slice(0, 2).toUpperCase();
    return initials || '';
  };


  const switchToOldInterface = ()=>{
  
    Cookies.remove('new_interface_v1',{ path: '/', domain: '.ryzeo.com' });
    if(switchInterface)
    window.location.href = 'https://app.ryzeo.com';
    setSwitchInterface(false)

      }

  return (
    <ErrorBoundary>
      
      <div className="sideBarBackground border-b md:px-6 px-4 dark:bg-transparent flex items-center justify-center h-10 border-[#E9EBEC] dark:border-[#41464E]" >
      <p className='text-white'>Switch to old interface </p>

      <button  type="button" onClick={switchToOldInterface} className="font-nomral text-13 font-medium leading-5 rounded-md px-2 py-0.5 ml-2 over:opacity-90 ease-in duration-300 bg-white text-primary" >{switchInterface? "Switch": "Switching..." }</button>
      </div>
      
      <div>
        <header className="border-b bg-white md:px-6 px-4 dark:bg-transparent flex items-center h-[70px] border-[#E9EBEC] dark:border-[#E9EBEC]">
          <div
            ref={outsideRef}
            className="flex justify-between items-center w-full"
          >
            <div className="sm:hidden mr-4 h-5">
              <IconButton className="w-5 h-5" onClick={toggleSidebar}>
                <img
                  className="dark:invert dark:brightness"
                  src={Menu}
                  alt="Menu"
                />
              </IconButton>
            </div>
            {(pathname === home || pathname === `${listSegments}/${list}`) ? (<div></div>) : (<div className="md:w-10/12 sm:w-7/12 sm:block flex items-center ">
              <div className="relative md:w-96 sm:w-80 flex items-center py-2 px-4 rounded-md border-gray-200 bg-[#F3F3F9] dark:bg-[#41464E]">
                <input
                  className="text-11 bg-[#F3F3F9] font-medium placeholder:text-gray-500 leading-3 text-gray-500 w-full pl-6 h-6 focus:outline-none dark:text-white dark:placeholder:text-white dark:bg-[#41464E]"
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={userCtx?.filteredValue}
                  onChange={(e) => userCtx?.filterValueHandler(e.target.value)}
                />
                <img
                  className="absolute dark:invert dark:brightness-0"
                  src={SearchImage}
                  alt="Search"
                />
              </div>
            </div>)}
            <div className="flex items-center py-2 pl-4 dark:border-[#fff]">
              <Switcher />

              {/* The blew commented code is for bell Icon for notificaion */}

              {/* <div className="relative w-[30px] mx-4 inline-block">
                <span className="bg-red text-white rounded-full absolute -top-2 bg-[#EE6352] w-[19px] h-[17px] inline-block text-[12px] text-center pt-[0px] right-[-2px]">
                  3
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke={themeCtx?.theme === 'dark' ? '#fff' : 'currentColor'}
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                  />
                </svg>
              </div> */}
              <div className=" relative ">
                <div
                  onClick={() => setProfileDropdown((prev) => !prev)}
                  className="flex items-center justify-center h-[70px] sm:w-[150px]  cursor-pointer"
                >
                  <div className="sm:w-[36px] sm:h-[36px] w-[30px] h-[30px] rounded-full sideBarBackground flex justify-center items-center">
                    {/* <img
                      src="https://themesbrand.com/velzon/html/galaxy/assets/images/users/avatar-1.jpg"
                      className="rounded-full"
                      alt="dp"
                    /> */}
                    <p className="text-white font-medium ">
                      {getNameInitials(
                        userCtx?.currentUserData &&
                        userCtx?.currentUserData?.first_name +
                        userCtx.currentUserData.last_name
                      )}
                    </p>
                  </div>
                  <span className="ml-2 flex flex-col">
                    <span className="font-semibold text-[13px] text-[#495057] dark:text-[#CED4DA]">
                      {userCtx?.currentUserData?.first_name}
                    </span>

                  </span>
                </div>
                {profileDropdown && (
                  <div className="absolute w-full z-[9999] rounded shadow-md  bg-white">
                    <p className="text-xs text-[#495057] px-4 py-2">
                      Welcome{' '}
                      {userCtx?.currentUserData &&
                        userCtx?.currentUserData?.first_name?.split(' ')[0]}
                      !
                    </p>

                    {/* Profile and Settings */}

                    {/* <Link to={''}>
                      <div className="flex items-center px-4 h-9 hover:bg-[#F3F6F9] w-full">
                        <img className="w-4 h-6 mr-1.5" src={ProfileIcon} />
                        <p className="text-[#212529] text-sm">Profile </p>
                      </div>
                    </Link>
                    <Link to={''}>
                      <div className="flex items-center px-4 h-9 hover:bg-[#F3F6F9] w-full">
                        <img className="w-4 h-6 mr-1.5" src={SettingIcon} />
                        <p className="text-[#212529] text-sm">Setting </p>
                      </div>
                    </Link> */}

                    {/* <button
                      onClick={() => { setProfileDropdown(false) }}
                      type='button'
                      className="flex items-center px-4 h-9 hover:bg-[#F3F6F9] w-full"
                    >
                      <img className="w-4 h-6 mr-1.5" src={AccountIcon} />
                      <p className="text-[#212529] text-sm">Account</p>
                    </button> */}
                    
                    
                    {/*Account Link */}
                    {/* <Link
                      onClick={() => { setProfileDropdown(false) }}
                      to={account}
                      className="flex items-center px-4 h-9 hover:bg-[#F3F6F9] w-full"
                    >
                      <img className="w-4 h-6 mr-1.5" src={UsersIcon} />
                      <p className="text-[#212529] text-sm">Account</p>
                    </Link> */}



                    <Link
                      onClick={() => { setProfileDropdown(false) }}
                      to={users}
                      className="flex items-center px-4 h-9 hover:bg-[#F3F6F9] w-full"
                    >
                      <img className="w-4 h-6 mr-1.5" src={UsersIcon} />
                      <p className="text-[#212529] text-sm">Users</p>
                    </Link>
                    <button
                      onClick={logoutUser}
                      disabled={loading}
                      className="flex items-center px-4 h-9 hover:bg-[#F3F6F9] w-full"
                    >
                      <img className="w-4 h-6 mr-1.5" src={LogoutIcon} />
                      <p className="text-[#212529] text-sm">Logout </p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      </div>
    </ErrorBoundary>
  );
};
export default Header;
