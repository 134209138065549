import React, { FC } from 'react'
import { AccountSettingsType } from '.'


interface Props {

settings?: AccountSettingsType

}




const GoogleAnalyticsForm: FC<Props> = ({settings}) => {
  
  return (
    
    
    
    
    <div className='flex flex-col w-full'>

      <div className='flex w-full'>
      <div className='flex flex-col w-1/2 mr-3'>
      <label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white'>Campaign Source</label>
      <input defaultValue={settings?.googleAnalyticsInfo.compaignSource} type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
     
      </div >

      <div className='flex flex-col w-1/2'>
      <label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white'>Campaign Medium</label>
      <input defaultValue={settings?.googleAnalyticsInfo.compaignMedium} type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA] ' />
      
      </div>


      </div>

      <div className='flex w-full mt-3'>

      <div className='flex flex-col w-1/2 mr-3'>
      <label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white'>Campaign Content</label>
      <input defaultValue={settings?.googleAnalyticsInfo.compaignContent} type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
      
      </div >
      
      <div className='flex flex-col w-1/2'>
      <label className='text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white'>Campaign Name</label>
      <input defaultValue={settings?.googleAnalyticsInfo.compaignName} type='text' className='w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]' />
      
      </div>


      </div>

    </div>
  )
}

export default GoogleAnalyticsForm