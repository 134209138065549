import React, { FC } from 'react'
import EditWraper from './EditWraper'
import Wrapper from 'components/Wrapper';
import CloseIcon from 'assets/Images/Common/closeButtonIcon.svg';
import AccountSettingsEditForm from './AccountSettingsEditForm';
import { AccountSettingsType } from '.';

interface Props {
  onClose: (attribute: boolean) => void;
  accountSettings?: AccountSettingsType    
}
  
const EditSetting: FC<Props> = ({onClose, accountSettings}) => {
    return (
        <div className=" px-6 w-full fixed left-0 top-0 h-full z-10 bg-black-200 duration-300">
          <div>
            <EditWraper  onClose={() => {onClose(false)}}>
              <>                
                <div className="flex justify-between items-center px-5 border-b dark:border-b-0 border-[#e9ebec] py-[18px]">
                  <div className="flex items-start">
                    <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                      Edit Account Settings
                    </p>
                  </div>
                  <img
                    onClick={() => onClose(false)}
                    src={CloseIcon}
                    alt="CloseIcon"
                    className="w-5 h-5 cursor-pointer"
                  />
                </div>
                <div className="flex w-full justify-between px-5 mx-auto gap-4 pb-5">
                    <Wrapper parentClass="overflow-auto" childClass="overflow-auto max-h-[500px]">
                    <AccountSettingsEditForm accountSettings={accountSettings}  />
                    </Wrapper>
                </div>
                  </>

            </EditWraper>
          </div>
        </div>
      );
}

export default EditSetting