import Wrapper from 'components/Wrapper'
import React, { FC } from 'react'
import { CompanyInfoType } from '.'

interface Props {
  companyInfo?: CompanyInfoType
}

const Company: FC<Props> = ({companyInfo}) => {
  return (
    <div className='mx-4'>
    <Wrapper parentClass="md:w-[100%]">
    <>
      <div className="flex items-center justify-center dark:border-[#fff]">
        <h1 className="px-4 py-2 text-black-400 text-xl font-normal mt-4"> {companyInfo?.companyName} </h1>
    </div>

      <div className="flex items-center border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Customer ID
        </p>
        <p className="px-4 py-2 text-sm text-[#878A99]">{companyInfo?.customerId}</p>
      </div>

      <div className="flex items-center  border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Email
        </p>
          <div className='flex flex-col'>        

          <div className='flex'>
          <p className=" mr-1 py-2 text-[#495057] text-sm font-medium">Primary</p>
          <p className="py-2 text-sm text-[#878A99]">  : {companyInfo?.companeyEmail.primary} 
          </p>
          </div>

          <div className='flex'>
          <p className="mr-1 py-2 text-[#495057] text-sm font-medium"> Login Email</p>
          <p className="py-2 text-sm text-[#878A99]"> : {companyInfo?.companeyEmail.login}
          </p>
          </div>

          </div>
        
      </div>

      <div className="flex items-center  border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Address
        </p>
        {
          <p className="px-4 py-2 text-sm text-[#878A99]">
            {companyInfo?.companyAddress}
          </p>
        }
      </div>
      <div className="flex items-center  border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Phone
        </p>
        {
          <p className="px-4 py-2 text-sm text-[#878A99]">
            {companyInfo?.companyPhone}
          </p>
        }
      </div>
      <div className="flex items-center  border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Website
        </p>
        {
          <p className="px-4 py-2 text-sm text-[#878A99]">
            {companyInfo?.companyWebsite}
          </p>
        }
      </div>
      <div className="flex items-center  border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Job Function / Title
        </p>
        {
          <p className="px-4 py-2 text-sm text-[#878A99]">
           {companyInfo?.jobFunction}
          </p>
        }
      </div>
      <div className="flex items-center  border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Industry
        </p>
        {
          <p className="px-4 py-2 text-sm text-[#878A99]">
           {companyInfo?.industry}
          </p>
        }
      </div>
      <div className="flex items-center  border-b justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-[#495057] text-sm font-medium">
          Employees
        </p>
        {
          <p className="px-4 py-2 text-sm text-[#878A99]">
           {companyInfo?.employeeRange.min} - {companyInfo?.employeeRange.max}
          </p>
        }
      </div>
    </>
  </Wrapper>
  </div>
  )
}

export default Company;