import React, { useEffect, useState } from 'react'
import Billing from './Billing'
import PlanAndQouta from './PlanAndQouta'
import Settings from './Settings'
import Company from './Company'
import EditBilling from './EditBilling'
import EditSetting from './EditSetting'
import { getCreditCardInfo, getAccountSettingsInfo, getPlanInfo, getCompanyInfo } from 'services/apiHandlers/Account/AccountInformation'
import {z} from 'zod'

const creditCardSchema = z.object({
cardHolderName: z.string(),
cardNumber: z.number(),
cardExpiry: z.string(),
cardCCV: z.number(),
cardCompany: z.string(),
});

const accountsSettingSchema = z.object({
owner: z.string(),
timezone: z.string(),
showSocialBar: z.boolean(),
messageEncoding: z.string(),
googleAnalytics: z.boolean(),

googleAnalyticsInfo: z.object({
  compaignSource: z.string(),
  compaignMedium: z.string(),
  compaignContent: z.string(),
  compaignName: z.string()
}),
  
MailingAddress: z.object({
  street1: z.string(),
  street2: z.string(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  country: z.string(),

})

});


const companyInfoSchema = z.object({
  companyName: z.string(),
  customerId: z.string(),
  companeyEmail: z.object({primary: z.string(), login: z.string()}),
  companyAddress: z.string(),
  companyPhone: z.number(),
  companyWebsite: z.string(),
  jobFunction: z.string(),
  industry: z.string(),
  employeeRange: z.object({min: z.number(), max: z.number()})

});

const planAndQoutaSchema = z.object({
  planName: z.string(),
  emails: z.number(),
  additionalThousand: z.boolean(),
  monthlyPrice: z.number(),
  usedEmails: z.number(),
  currentBillingPeriod: z.object({start: z.date(), end: z.date() })
})

export type CreditCardInfoType = z.infer<typeof creditCardSchema>;
export type AccountSettingsType = z.infer<typeof accountsSettingSchema>;
export type CompanyInfoType = z.infer<typeof companyInfoSchema>;
export type PlanInfoType = z.infer<typeof planAndQoutaSchema>;

const Account = () => {

const [editBilling, setEditBilling] = useState<boolean>(false);
const [editSettings, setEditSettings] = useState<boolean>(false);
const [creditCardInfo, setCreditCardInfo] = useState<CreditCardInfoType>();
const [accountSettingsInfo, setAccountSettingsInfo] = useState<AccountSettingsType>();
const [companyInfo, setCompanyInfo] = useState<CompanyInfoType>();
const [planAndQouta, setPlanAndQouta]= useState<PlanInfoType>();

const getCardData = async () => {
  try {
    const cardResponseData = (await getCreditCardInfo()) as CreditCardInfoType;
    setCreditCardInfo(cardResponseData);
  } catch (error) {
   console.log({error})
  }
};

const getSettingsdData = async () => {
  try {
    const accountResponseData = (await getAccountSettingsInfo()) as AccountSettingsType;
    
    setAccountSettingsInfo(accountResponseData);
    
  } catch (error) {
    console.log({error})
  }
};

const getPlanData = async () => {
  try {
    const planResponseData = (await getPlanInfo()) as PlanInfoType;
  
    setPlanAndQouta(planResponseData);
  } catch (error) {
    console.log({error})
  }
};

const getCompanyData = async () => {
  try {
    const companyResponseData = (await getCompanyInfo()) as CompanyInfoType;
    console.log(companyResponseData);
    setCompanyInfo(companyResponseData);
  } catch (error) {
    console.log({error})
  }
};

useEffect(()=>{
getCardData();
getSettingsdData();
getPlanData();
getCompanyData();

},[])

  return (
    <div>
      <div className="flex justify-evenly">
        <Billing cardInfo={creditCardInfo} showEditing = {setEditBilling}  />
        <PlanAndQouta planInfo={planAndQouta}  />
      </div>
    <h1 className="font-normal text-black-400 ml-4 mt-4 text-xl">Settings</h1>
      <Settings accountSettings = {accountSettingsInfo} showEditing = {setEditSettings}/>
    <h1 className="font-normal text-black-400 ml-4 mt-4 text-xl">Company</h1>
    <Company companyInfo={companyInfo} />

    {editBilling && <EditBilling cardInfo = {creditCardInfo}  onClose={setEditBilling}/>}
    {editSettings && <EditSetting accountSettings = {accountSettingsInfo}  onClose={setEditSettings}/>}
    </div>
  );
}

export default Account