import React,{ useContext, useEffect, useState } from 'react';
import Dropdown from 'components/Dropdown/Dropdown';
import { CampaignContext } from 'store/CampaignContext';
import { excludedValues } from './ScheduleTime';

import moment from 'moment';
const weekDays = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 },
];
const dateRepeats = [
  'does not repeat',
  'repeats daily',
  'repeats weekly',
  'repeats monthly',
  'repeats yearly',
];
interface SelectedFrequency {
  [key: string]: string[];
  ['does not repeat']: string[];
  ['repeats daily']: string[];
  ['repeats weekly']: string[];
  ['repeats monthly']: string[];
  ['repeats yearly']: string[];
}
interface DefaultFrequency {
  [key: string]: string;
  ['does not repeat']: string;
  ['daily']: string;
  ['weekly']: string;
  ['monthly']: string;
  ['yearly']: string;
}
const selectedFrequency: SelectedFrequency = {
  ['does not repeat']: ['does not repeat', 'does not repeat'],
  ['repeats daily']: ['days', 'daily'],
  ['repeats weekly']: ['week', 'weekly'],
  ['repeats monthly']: ['month', 'monthly'],
  ['repeats yearly']: ['year', 'yearly'],
};
const defaultFrequency: DefaultFrequency = {
  'does not repeat': 'does not repeat',
  'daily': 'repeats daily',
  'weekly': 'repeats weekly',
  'monthly': 'repeats monthly',
  'yearly': 'repeats yearly',
}
const ScheduleDate = () => {
  const campaignCtx = useContext(CampaignContext);
  const [days, setDays] = useState<Array<string>>([]);
  const [months, setMonths] = useState<Array<string>>([]);
  const [years, setYears] = useState<Array<string>>([]);
  const [date, setDate] = useState({
    day: campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['day'] || moment().format('D'),
    month: campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['month'] || moment().format('MMMM'),
    year: campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['year'] || moment().format('YYYY'),
    frequency: campaignCtx?.campaignData?.schedule_later && defaultFrequency[campaignCtx?.campaignData?.schedule_later['frequency']] || 'does not repeat',
    interval: campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['interval'] || '1',
    days_of_week: campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['days_of_week'] || [0, 0, 0, 0, 0, 0, 0],
  });
  const onSelectHandler = (value: string | number | number[], name: string) => {
    if (campaignCtx?.campaignData?.schedule_later && campaignCtx.campaignData) {
      let finalValue = value;
      if (name === 'month') {
        finalValue = moment(value, 'MMMM').format('M');
      }
      if (name === 'frequency' && typeof finalValue === 'string') {
        finalValue = selectedFrequency[finalValue][1];
      }
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        schedule_later: {
          ...campaignCtx.campaignData.schedule_later,
          [name]: finalValue,
        },
      });
    }
    setDate({ ...date, [name]: value });
  };

  useEffect(() => {
    const tempScheduleDays = Array.from(Array(moment(`${date.year}-${date.month}`).daysInMonth()), (_, i) => i + 1)
    const scheduleDays = tempScheduleDays.map(String);
    setDays(scheduleDays);
  }, [date.year, date.month])

  useEffect(() => {
    const nextFiveYears = Array.from(
      { length: 6 },
      (_, i) => moment().year() + i
    );
    setMonths(moment.months());
    setYears(nextFiveYears.map(String));
  }, []);

  return (
    <div className="mt-2">
      <div>
        <label className="radio-btn block relative text-13 font-normal leading-3 text-[#495057] dark:text-[#CED4DA]  flex items-center"></label>
        <div className="flex flex-wrap items-center">
          <div className="w-fit-content h-11 flex items-center relative mt-4 mr-4">
            <Dropdown
              options={months}
              onSelect={(value) => onSelectHandler(value, 'month')}
              value={date.month.toString()}
            />
          </div>
          <div className="w-fit-content h-11 flex items-center relative mt-4 mr-4">
            <Dropdown
              options={days}
              onSelect={(value) => onSelectHandler(value, 'day')}
              value={date.day.toString()}
            />
          </div>
          <div className="w-fit-content h-11 flex items-center relative mr-4 mt-4">
            <Dropdown
              options={years}
              onSelect={(value) => onSelectHandler(value, 'year')}
              value={date.year.toString()}
            />
          </div>
        </div>
        <div className='flex items-center my-4'>
          <p className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white mr-4 ">
            and
          </p>
          <div className="w-[250px] h-[38px]  flex items-center relative ">
            <Dropdown
              options={dateRepeats}
              onSelect={(value) => onSelectHandler(value, 'frequency')}
              value={date.frequency}
            />
          </div>
        </div>
        {date.frequency !== 'does not repeat' && (
          <div className='my-4 flex items-center'>
            <p className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white ">
              every
            </p>
            <input
              className="border border-[#CED4DA] rounded text-13 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]   h-[38px] p-4 sm:w-64 w-8/12  dark:bg-[#41464E]  dark:border-[#fff] focus:outline-none dark:text-white mx-3"
              type="number"
              name="trigger"
              value={date.interval}
              onKeyDown={(e) => {
                if (excludedValues.includes(e.key)) {
                  e.preventDefault()
                }
              }}
              onChange={({ target: { value } }) => {

                if (parseInt(value) >= 0 || value === '') {
                  onSelectHandler(value, 'interval')
                }
              }}
              min={0}
            />
            <p className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white  ">
              {selectedFrequency[date.frequency][0]}
            </p>
          </div>
        )}
        {date.frequency === 'repeats weekly' && (
          <div className="flex wrap mb-4">
            {weekDays.map((week, index) => (
              <div key={week.value} className="flex items-center mt-4 mr-4">
                <input
                  className="w-[18px] h-[18px] rounded-sm accent-blue-600 mr-2"
                  type="checkbox"
                  id={week.value.toString()}
                  name="resume-mon"
                  value={week.value}
                  checked={date.days_of_week.includes(week.value)}
                  onChange={(e) => {
                    const tempWeekDays = date.days_of_week;
                    if (e.target.checked) {
                      tempWeekDays[index] = parseInt(e.target.value);
                    } else {
                      tempWeekDays[index] = 0;
                    }
                    onSelectHandler(tempWeekDays, 'days_of_week');
                  }}
                  min={0}
                />
                <label
                  htmlFor={week.value.toString()}
                  className="font-sm font-normal leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white"
                >
                  {week.name}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default ScheduleDate;