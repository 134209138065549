import Wrapper from 'components/Wrapper'
import React, { FC } from 'react'
import { PlanInfoType } from '.'
import moment from 'moment'

interface Props {

  planInfo?: PlanInfoType


}






const PlanAndQouta: FC<Props> = ({planInfo}) => {
  return (
    
<Wrapper parentClass="md:w-[55%]">
    <>
    <div className="flex items-center border-b mb-8  justify-between dark:border-[#fff]">
        <p className="px-4 py-2 text-black-400 text-xl font-normal">Plan and Qouta</p>
        <p className='px-4 py-2 text-[#878A99] text-sm font-normal'>{ moment(planInfo?.currentBillingPeriod.start).format("MM/DD/YYYY")} - {moment(planInfo?.currentBillingPeriod.end).format("MM/DD/YYYY")} </p>
    </div>

      <div className="flex items-center border-b justify-between dark:border-[#fff]">
        <div className='w-1/4'>
        <p className="px-4 py-2 text-[#495057] text-sm font-medium"> Name</p>
        </div>
        <div className='w-1/4'>
        <p className="px-4 py-2 text-sm text-[#495057]">E-mails</p>
        </div>
        <div className='w-1/4'>
        <p className="px-4 py-2 text-sm text-[#495057]">Additional 1000</p>
        </div>
        <div className='w-1/4'>
        <p className="px-4 py-2 text-sm text-[#495057]">Monthly Price</p>
        </div>
      </div>

      <div className="flex items-center justify-between dark:border-[#fff]">
      <div className='w-1/4'>
        <p className="px-4 py-2 text-[#878A99] text-sm font-medium">{planInfo?.planName}</p>
      </div>
      <div className='w-1/4'>
        <p className="px-4 py-2 text-[#878A99] text-sm font-medium">{planInfo?.emails}</p>
      </div>
      <div className='w-1/4'>
        <p className="px-4 py-2 text-[#878A99] text-sm font-medium">{planInfo?.additionalThousand}</p>
        </div>
        <div className='w-1/4'>
        <p className="px-4 py-2 text-[#878A99] text-sm font-medium">${planInfo?.monthlyPrice}</p>
        </div>
    </div>
    <div className='flex justify-end align-bottom mt-5'>
    <p className="px-4 py-2 text-[#878A99] text-sm font-normal" >{planInfo?.usedEmails} out of {planInfo?.emails} Emails used</p>
    </div>
      </>
  </Wrapper>




  )
}

export default PlanAndQouta