import React, { FC, useContext, useEffect } from 'react';
import { useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { tableSort } from 'utils/common';
import CampaignNames from 'components/CompaignColumns/CompaignNames';
// import CampaignStatus from 'components/CompaignColumns/CompaignStatus';
import CampaignActions from 'components/CompaignColumns/CompaignActions';
import { z } from 'zod';
import { UserContext } from 'store/UserContext';
import moment from 'moment-timezone';
import Dropdown from 'components/Dropdown/Dropdown';

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});
type SinglecampaignEmail = z.infer<typeof campaignEmailSchema>;

interface Props {
  emails: SinglecampaignEmail[];
  readonly: boolean;
  loading: boolean;
  onDelete: () => void;
  total: number;
  onPageChange: (page: number) => void;
  setShowPopularLabels: (show: boolean) => void;
  label: string | undefined;
  selectedFilter: string,
  setSelectedFilter: (filter: string)=>void

}

const Email: FC<Props> = ({
  emails,
  readonly,
  loading,
  onDelete,
  total,
  onPageChange,
  setShowPopularLabels,
  label,
  selectedFilter, 
  setSelectedFilter
}) => {
  const [actionTooltipOpen, setActionTooltipOpen] = useState<boolean>(false);
  const [filteredEmails, setFilteredEmails] = useState<Array<SinglecampaignEmail>>([]);
  // const [selectedFilter, setSelectedFilter] = useState<string>('Select a Filter');

  const userCtx = useContext(UserContext);

  const handlePageChange = (page: number) => {
    onPageChange(page);
    setSelectedFilter(selectedFilter)
    
  };

  const campaignSort = (
    rowA: SinglecampaignEmail,
    rowB: SinglecampaignEmail
  ) => {
    return tableSort(rowA.name, rowB.name);
  };

  const columns: TableColumn<SinglecampaignEmail>[] = useMemo(
    () => [
      {
        name: <div className="px-3">Name</div>,
        cell: (row, index) => {
          return <CampaignNames row={row} index={index} />;
        },
        sortable: true,
        sortFunction: campaignSort,
      },
      {
        name: 'Created At',
        cell: (row) => {
          return (
            <div className="">
              <div className=" dark:text-white">
                {moment
                  .tz(row.created_at, userCtx?.usersData?.timeZone || '')
                  .format('MMMM D, YYYY [at] h:mm A')}
              </div>
            </div>
          );
        },
        width: '350px',
      },
      {
        name: 'Actions',
        omit: !!readonly,
        cell: (row: SinglecampaignEmail) => {
          return (
            <CampaignActions
              row={row}
              actionTooltipOpen={actionTooltipOpen}
              setActionTooltipOpen={setActionTooltipOpen}
              onDelete={onDelete}
            />
          );
        },
        width: '270px',
      },
    ],
    [readonly, actionTooltipOpen, filteredEmails, selectedFilter]
  );

  const filterChangeHandler = (filter: string) => {
    setSelectedFilter(filter);
    const tempInsights: SinglecampaignEmail[] = [];
    const tempEmails: SinglecampaignEmail[] = [];
    emails?.map((data) => {
      if (!data.include_event) {
        tempEmails.push(data);
      } else {
        tempInsights.push(data);
      }
    });

    if (filter === 'Emails') {
      setFilteredEmails(tempEmails);
    } else if (filter === 'Insights') {
      setFilteredEmails(tempInsights);
    } else {
      console.log("its runing nothin", filter)
      setFilteredEmails(emails);
    }
  };



  useEffect(() => {
    filterChangeHandler(selectedFilter)
  }, [emails]);



  return (
    <>
      <div className="flex flex-wrap justify-between items-center px-5 border-b border-[#e9ebec] py-[18px] dark:border-[#fff]">
        <div className="flex items-center ">
          <p className="text-base text-[#495057] dark:text-[#CED4DA] font-medium ">
            Email Templates
          </p>
          <p
            className={` text-xs ml-2 ${
              label && 'p-1'
            } rounded bg-primaryHover text-white `}
          >
            {label}
          </p>
        </div>
        <div className="flex items-center justify-between ">
          <button
            type="button"
            className="text-sm text-primary underline h-[38px] min-w-[120px]"
            onClick={() => {
              setShowPopularLabels(true);
            }}
          >
            Popular Labels
          </button>
          <Dropdown
            options={['All', 'Emails', 'Insights']}
            value={selectedFilter}
            onSelect={(option) => filterChangeHandler(option)}
          />
        </div>
      </div>
      <Table
        onRowMouseLeave={() => {
          setActionTooltipOpen(false);
        }}
        pagination={true}
        paginationTotalRows={total}
        paginationServer
        paginationPerPage={20}
        onChangePage={(page)=>{handlePageChange(page)}}
        className="scrollbar-hide"
        data={filteredEmails}
        columns={columns}
        progressPending={loading}
      />
    </>
  );
};
export default Email;
