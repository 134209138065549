import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { calendar, campaignReport, campaigns } from 'services/constant/routes';
import React from 'react';
import {
  CalendarEvents,
  CalendarEventsData,
  FilteredEvents,
} from '../index.type';
import Wrapper from 'components/Wrapper';

interface Props {
  campaignEvents: CalendarEvents[] | undefined;
  loading: boolean;
}

const getEvents = (events: CalendarEventsData[]): FilteredEvents[] => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const result: FilteredEvents[] = daysOfWeek.map((day) => ({
    name: day,
    events: [],
    id: '',
  }));

  events.forEach((day) => {
    const { start, title, id } = day;
    const dayIndex = daysOfWeek.indexOf(start);

    if (dayIndex !== -1) {
      result[dayIndex].events.push({ name: title, id: id });
      if (result[dayIndex].id === '') {
        result[dayIndex].id = id;
      }
    }
  });

  result.forEach((item, index) => {
    if (item.id === '') {
      item.id = index.toString();
    }
  });

  return result;
};


const Calendar: FC<Props> = ({ campaignEvents, loading }) => {
  const [events, setEvents] = useState<Array<FilteredEvents>>([]);
  
  useEffect(() => {
    const tempCampaignEvents = campaignEvents?.map((campaign) => ({
      title: campaign.name,
      start: moment(campaign.events).format('ddd'),
      id: campaign.id,
    }));

    setEvents(getEvents(tempCampaignEvents || []));
  }, [campaignEvents]);

  return (
    <Wrapper parentClass="!mt-0">
      <div className=" shadow-lg hover:shadow-xl  overflow-hidden hover:shadow-gray-200 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg dark:hover:shadow-2xl ">
        <div className="cardHeader dark:border-[#fff] min-h-[71px]">
          <h4 className="text-base text-darkText font-medium dark:text-[#878A99]">
          Upcoming Campaigns
          </h4>
        </div>
        <div>
          <div className="bg-[rgb(252, 253, 255)] border-b border-[#ced4da] dark:border-[#fff]  dark:bg-none flex items-center justify-between font-semibold text-sm text-darkText dark:text-[#CED4DA]">
            <div className="">
              <h4 className="w-3/12 font-medium text-darkText dark:text-[#878a99] p-4">
                Days
              </h4>
            </div>
            <div className="w-9/12 pl-4 border-l border-[#ced4da]  dark:border-[#fff] flex items-center h-[52px] relative -left-[1px]">
              <h4 className="font-medium text-darkText dark:text-[#878a99]">
                Campaign
              </h4>
            </div>
          </div>
          <div>
            {events?.map((day) => (
              <div
                key={day.id}
                className="flex items-center justify-between text-xs border-b dark:border-[#fff] border-[#ced4da]"
              >
                <div className="w-3/12 border-r px-4 h-[46.8px] flex items-center dark:border-[#fff]  border-[#ced4da]">
                  <h4 className="text-darkText dark:text-[#CED4DA]">
                    {day.name}
                  </h4>
                </div>

                <div className="w-9/12 p-2 max-h-[46px] overflow-hidden flex justify-between">
                  <div className="flex flex-wrap gap-1 w-[73%]">
                    {loading ? (
                      <span className="bg-blue-50 dark:rounded text-primary px-1 mr-2">
                        ---------------
                      </span> 
                    ) : day?.events?.length === 0 ? (
                      <span className="bg-blue-50 dark:rounded text-primary px-1 mr-2">

                        No Campaign

                      </span>
                    ) : day?.events?.length > 2 ? (
                      <>
                        {day?.events?.slice(0, 2)?.map((event) => (
                          <Link
                            key={event.id}
                            to={`${campaignReport}/${event.id}`}
                          >
                            <span className="bg-blue-50 dark:rounded text-primary px-1 mr-2">
                              {event.name}
                            </span>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <span>
                        {day?.events?.map((event) => (
                          <Link
                            key={event.id}
                            to={`${campaignReport}/${event.id}`}
                          >
                            <span className="bg-blue-50 text-primary dark:rounded capitalize m-1 px-1 ">
                              {event.name}
                            </span>
                          </Link>
                        ))}
                      </span>
                    )}
                  </div>
                  {day?.events?.length > 2 && (
                    <div className="">
                      <Link
                        to={`${campaigns}/${calendar}`}
                        className="bg-pink-100 min-w-[61px] lg:text-xs text-[10px] rounded text-pink-400 px-1 py-0.5 block"
                      >
                        Show More
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Calendar;
