import httpService from 'services/core/HttpService';
import {
  GET_WORKFLOW_REPORTS,
  GET_BLAST_BY_STATUS,
  GET_BLAST_MONETARY_VALUE,
  GET_WEEKLY_ROI_REPORT,
  GET_ALL_WORKFLOWS,
  GET_SUBSCRIBER_SUMMARY,
  GET_ALL_BLASTS,
  GET_SINGLE_CAMPAIGN,
  GET_LATEST_SUBSCRIBERS
} from 'services/constant/apiRoutes';
import { z } from 'zod';

const PageDataSchema = z.object({
  page: z.number(),
  per_page: z.number(),
});
type PageData = z.infer<typeof PageDataSchema>;

export const getWorkflowReports = async (
  startDate: string | undefined,
  endDate: string | undefined
) => {
  const getWorkflowReportsUrl =
    GET_WORKFLOW_REPORTS + `endDate=${endDate}&startDate=${startDate}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(getWorkflowReportsUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBlastByStatus = async (
  startDate?: string | undefined,
  endDate?: string | undefined
) => {
  const getBlastByStatusUrl =
    GET_BLAST_BY_STATUS + `status=sending&start_date=${startDate}&end_date=${endDate}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(getBlastByStatusUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBlastMonetaryValue = async (id: string) => {
  const getBlastMonetaryValueUrl =
    GET_BLAST_MONETARY_VALUE + `${id === '' ? '' : `?blastIds=${id}`}`;
  if (id === '') {
    return;
  } else {
    return new Promise((resolve, reject) => {
      httpService
        .get(getBlastMonetaryValueUrl)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export const getWeeklyRoiReport = async () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_WEEKLY_ROI_REPORT)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllWorkflows = async () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_ALL_WORKFLOWS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getSubscriberSummary = async (
  startDate: string | Date | undefined,
  endDate: string | Date | undefined
) => {
  const getSubscriberSummaryUrl =
    GET_SUBSCRIBER_SUMMARY + `start_date=${startDate}&end_date=${endDate}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(getSubscriberSummaryUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getLatestSubscribers = async () => {
    return new Promise((resolve,reject)=>{
      httpService
        .get(GET_LATEST_SUBSCRIBERS)
        .then((response)=>{
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
    });
};

export const getAllBlasts = (status: string, pageData: PageData) => {
  const getBlastUrl =
    GET_ALL_BLASTS +
    `?status=${status}&page=${pageData.page}&per_page=${pageData.per_page}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(getBlastUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSingleCampaign = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_SINGLE_CAMPAIGN + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSingleCampaignClickData = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(`report/blast/${id}/click.json`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
