import React, { FC, useContext, useEffect } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { previewEmail } from 'services/constant/routes';
import { Option,CampaignEmail } from './Types';
import uuid from 'react-uuid';

interface Props {
  onEmailChange: (id: string) => void;
  selectedTemplate: string;
  setSelectedTemplate: (name: string) => void;
  setShowEmailTemplateError: (show: boolean) => void;
  showEmailTemplateError: boolean;
  emailOptions: Option[];
  campaigns: CampaignEmail[];
}

const EmailTemplates: FC<Props> = ({
  selectedTemplate,
  setSelectedTemplate,
  onEmailChange,
  showEmailTemplateError,
  setShowEmailTemplateError,
  emailOptions,
  campaigns
}) => {
  const campaignCtx = useContext(CampaignContext);
  const { id } = useParams();

  const onSelectHandler = (selection: Option) => {
    onEmailChange(selection.value);
    setSelectedTemplate(selection.label);
    if (campaignCtx?.campaignData) {
      campaignCtx.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        email_id: parseInt(selection.value),
      });
    }
  };
  useEffect(() => {
    if (campaignCtx?.campaignFormErrors.emailTemplateError) {
      setShowEmailTemplateError(true);
    } else {
      setShowEmailTemplateError(false);
    }
  }, [campaignCtx?.campaignFormErrors]);

  useEffect(() => {
    campaignCtx?.onChangeFormValidity('emailTemplateError', false);
  }, [campaignCtx?.campaignData?.email_id]);

  useEffect(() => {
    if (id) {
      const emailId = id;
      if (campaignCtx?.campaignData) {
        campaignCtx.onUpdateCampaignData({
          ...campaignCtx.campaignData,
          email_id: emailId ? parseInt(emailId) : 0,
        });
      }
    }
  }, [campaigns]);

  return (
    <div className="relative mt-4">
      <h3 className=" text-lg  leading-5 text-[#495057] font-medium dark:text-white">
        Email Templates
      </h3>
      <div className="py-4">
        <Select
          onChange={(selectedOption) =>
            onSelectHandler({
              value: selectedOption?.value || '',
              label: selectedOption?.label || '',
            })
          }
          isSearchable={true}
          options={emailOptions}
          value={{ value: selectedTemplate, label: selectedTemplate }}
        />
      </div>
      <Link
        target="_blank"
        onClick={(e) => {
          if (campaignCtx?.campaignData?.email_id === 0) {
            e.preventDefault();
          } else {
            window.open(
              `${previewEmail}/${campaignCtx?.campaignData?.email_id}`,
              `html${uuid()}`,
              `width=800,height=${window.screen.availHeight}`
            );
          }
        }}
        to={`${previewEmail}/${campaignCtx?.campaignData?.email_id}`}
        className="inline-flex items-center text-xs font-medium text-ryzeoBlue float-right"
      >
        <span className="w-3 h-3 justify-center items-center inline-block rounded-full bg-primary text-white text-xs font-bold mr-1">
          +
        </span>
        <span className="cursor-pointer text-xs font-medium text-primary underline">
          Preview Email
        </span>
      </Link>
      {showEmailTemplateError && (
        <div className="text-xs leading-4 text-red-400 mt-1.5">
          Select an email Template
        </div>
      )}
    </div>
  );
};
export default EmailTemplates;
